import { Datagrid, DateInput, List, NumberField, RadioButtonGroupInput, TextField, useTranslate } from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import dayjs from "dayjs";

import { ChildStatisticListActions } from "./components/ChildStatisticListActions";

export function ChildStatisticList(): JSX.Element {
    const t = useTranslate();
    const formatedStartDate = dayjs().startOf("year").format("YYYY-MM-DD");
    const formatedEndDate = dayjs().endOf("year").format("YYYY-MM-DD");

    const filters = [
        <DateInput key="start-date" source="startDate" label={t("resources.childStatistic.filters.startDate")} />,
        <DateInput key="end-date" source="endDate" label={t("resources.childStatistic.filters.endDate")} />,
        <RadioButtonGroupInput
            key="test-account"
            label={t("resources.productStatistic.filters.testAccount")}
            source="testAccount"
            choices={[
                { id: "false", name: "resources.productStatistic.choices.testAccount.false" },
                { id: "true", name: "resources.productStatistic.choices.testAccount.true" }
            ]}
        />
    ];

    return (
        <>
            <List
                pagination={<ListPagination />}
                perPage={20}
                filters={filters}
                filterDefaultValues={{ startDate: formatedStartDate, endDate: formatedEndDate }}
                actions={<ChildStatisticListActions />}
            >
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="childId" label={t("resources.childStatistic.fields.childId")} />
                    <TextField source="createdAt" label={t("resources.childStatistic.fields.lastConsumptionDate")} />
                    <NumberField source="playCount" label={t("resources.childStatistic.fields.playCount")} />
                    <NumberField source="licenseCount" label={t("resources.childStatistic.fields.licenseCount")} />
                </Datagrid>
            </List>
        </>
    );
}
