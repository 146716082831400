import iconv from "iconv-lite";

export const downloadCSV = (csv: string, filename: string): void => {
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    const newCsv = iconv.encode(csv.replace(/’/g, "'"), "iso-8859-15");
    const blob = new Blob([newCsv], { type: "text/csv;charset=iso-8859-15" });
    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    } else {
        fakeLink.setAttribute("href", URL.createObjectURL(blob));
        fakeLink.setAttribute("download", `${filename}.csv`);
        fakeLink.click();
    }
};
