import { SelectInput, useTranslate } from "react-admin";

interface Props {
    source: string;
    label: string;
}

export function ContentStatusFilter(props: Props) {
    const t = useTranslate();

    return (
        <SelectInput
            key="filter-status"
            label={props.label}
            source={props.source}
            choices={[
                { id: "draft", name: t("resources.common.choices.contentStatus.draft") },
                { id: "publishable", name: t("resources.common.choices.contentStatus.publishable") }
            ]}
        />
    );
}
