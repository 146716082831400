import {
    AutocompleteInput,
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    List,
    ListButton,
    ReferenceField,
    ReferenceInput,
    ShowButton,
    TextField,
    TextInput,
    useGetRecordId,
    useTranslate
} from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import { ContentStatusField } from "../../utils/components/ContentStatusField";
import { PublicationStatusField } from "../../utils/components/PublicationStatusField";
import { SeasonListActions } from "./components/SeasonListActions";
import { ContentStatusFilter } from "../../utils/components/form/ContentStatusFilter";
import { PublicationStatusFilter } from "../../utils/components/form/PublicationStatusFilter";

const classes = {
    booleanField: { position: "relative", bottom: "13px", marginLeft: "10px" }
};

function EpisodeListButton() {
    const recordId = useGetRecordId();
    var searchParams = new URLSearchParams({ filter: JSON.stringify({ seasonUuid: recordId }) });

    return (
        <ListButton
            resource="product"
            label="resources.season.episodeList"
            to={{
                pathname: "/product",
                search: searchParams.toString()
            }}
        />
    );
}

export function SeasonList(): JSX.Element {
    const t = useTranslate();

    const filters = [
        <TextInput key="searchTerms-filter" label={t("resources.season.fields.name")} source="searchTerms" alwaysOn />,
        <ReferenceInput key="filter-serie" label={t("resources.season.fields.serie")} source="serieUuid" reference="serie">
            <AutocompleteInput label={t("resources.season.fields.serie")} optionValue="id" optionText="name" />
        </ReferenceInput>,
        <DateInput
            key="publication-from"
            source="rangePublicationDateBegin"
            label={t("resources.product.filters.rangePublicationDateBegin")}
        />,
        <DateInput key="publication-to" source="rangePublicationDateEnd" label={t("resources.product.filters.rangePublicationDateEnd")} />,

        <ContentStatusFilter source="status" label={t("resources.common.filters.status")} />,
        <PublicationStatusFilter source="publicationStatus" label={t("resources.common.filters.publicationStatus")} />,

        <TextInput key="searchTerms-id" label={t("resources.common.fields.id")} source="id" />
    ];

    return (
        <>
            <List
                hasCreate
                pagination={<ListPagination />}
                perPage={20}
                sort={{ field: "createdAt", order: "DESC" }}
                filters={filters}
                actions={<SeasonListActions />}
            >
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="name" sortBy="title" />
                    <TextField source="description" />
                    <ReferenceField source="serie.id" reference="serie" label={t("resources.season.fields.serie")}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ContentStatusField source="status" label={t("resources.common.fields.status")} />
                    <PublicationStatusField source="publicationStatus" label={t("resources.common.fields.publicationStatus")} />
                    <DateField label={t("resources.season.fields.publicationStartedAt")} source="publicationStartedAt" />
                    <DateField label={t("resources.season.fields.publicationEndedAt")} source="publicationEndedAt" />
                    <EpisodeListButton />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
        </>
    );
}
