import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import { convertIdFilter } from "../../utils/filterUtils";

import type { ChildStatisticDTO } from "./ChildStatisticDTO";
import type { ChildStatisticRecord } from "./ChildStatisticRecord";
import { arrayRange } from "../../utils/array";

const MAX_PAGE_SIZE = 500;

const findAllTranslated = fetcher.path("/api/statistic/child/findAll").method("post").create();

async function getPage(parameters: FetchArgType<typeof findAllTranslated>) {
    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: 0, ...content })),
        total
    };
}

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns UserRecord
 */

function mapDTOToRecord(dto: ChildStatisticDTO): ChildStatisticRecord {
    // DTO from backend
    const { childUuid, createdAt, playCount, licenseCount } = dto;

    // Record for front-end
    return {
        id: childUuid,
        childId: childUuid,
        createdAt,
        playCount,
        licenseCount
    } as ChildStatisticRecord;
}

interface ChildStatisticFilters extends BaseFilters {
    dateBegin?: string;
    dateEnd?: string;
    testAccount?: boolean;
}

function convertFilters(filters: ChildStatisticFilters): any {
    const newFilters = Object.assign({}, filters) as any;
    return convertIdFilter(newFilters);
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<ChildStatisticRecord>> {
    const { ...filters } = params.filter as ChildStatisticFilters;

    let parameters: FetchArgType<typeof findAllTranslated> = {
        filter: convertFilters(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    const pageable = parameters.pageable;
    const origPageSize = pageable?.size ?? 0;

    if (pageable?.page == 0 && origPageSize > MAX_PAGE_SIZE) {
        // Use pagination
        const pageIds = arrayRange(pageable?.page, origPageSize / MAX_PAGE_SIZE, 1);
        const pages = await Promise.all(
            pageIds.map((pageId) => {
                const newParameters: FetchArgType<typeof findAllTranslated> = {
                    filter: parameters.filter ?? {},
                    pageable: {
                        page: pageId,
                        size: MAX_PAGE_SIZE,
                        sort: parameters.pageable?.sort ?? []
                    }
                };
                return getPage(newParameters);
            })
        );

        const allData = [];

        for (const page of pages) {
            allData.push(...page.data);
        }

        return {
            data: allData.slice(0, origPageSize),
            total: pages?.[0]?.total ?? 0
        };
    }

    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: 0, ...content })),
        total
    };
}

async function getOne(_params: LangParams | (number | string)): Promise<ChildStatisticRecord> {
    return Promise.reject(new NotImplementedError("_getOne"));
}

async function getMany(_uuids: readonly (number | string)[]): Promise<GetManyResult<ChildStatisticRecord>> {
    return Promise.reject(new NotImplementedError("getMany"));
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<ChildStatisticRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(_params: DeepReadonly<UpdateParams<ChildStatisticRecord>>): Promise<UpdateResult<ChildStatisticRecord>> {
    return Promise.reject(new NotImplementedError("update"));
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(_params: DeepReadonly<CreateParams>): Promise<CreateResult<ChildStatisticRecord>> {
    return Promise.reject(new NotImplementedError("create"));
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(_uuid: number | string): Promise<ChildStatisticRecord> {
    return Promise.reject(new NotImplementedError("delete"));
}

async function deleteMany(_params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    return Promise.reject(new NotImplementedError("deleteMany"));
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<ChildStatisticRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const ChildStatisticDataProvider: MpmDataProvider<ChildStatisticRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne,
    update,
    updateMany
};
