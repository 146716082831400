import {
    AutocompleteInput,
    Datagrid,
    DateInput,
    List,
    NumberField,
    RadioButtonGroupInput,
    ReferenceInput,
    TextField,
    TextInput,
    useTranslate
} from "react-admin";

import dayjs from "dayjs";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import { ProductStatisticListActions } from "./components/ProductStatisticListActions";

export function ProductStatisticList(): JSX.Element {
    const t = useTranslate();
    const formatedStartDate = dayjs().startOf("year").format("YYYY-MM-DD");
    const formatedEndDate = dayjs().endOf("year").format("YYYY-MM-DD");

    const filters = [
        <TextInput key="filter-text" label={t("resources.productStatistic.filters.codeEm")} source="codeEm" />,
        <TextInput key="filter-text" label={t("resources.productStatistic.filters.codeEmArte")} source="codeEmArte" />,

        <ReferenceInput
            key="filter-contentType"
            label={t("resources.productStatistic.filters.contentType")}
            source="contentTypeUuid"
            reference="contentType"
        >
            <AutocompleteInput label={t("resources.productStatistic.filters.contentType")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput
            key="filter-rightHolder"
            label={t("resources.productStatistic.filters.rightHolder")}
            source="rightHolderUuid"
            reference="rightHolder"
        >
            <AutocompleteInput label={t("resources.productStatistic.filters.rightHolder")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput key="filter-format" label={t("resources.productStatistic.filters.format")} source="formatUuid" reference="format">
            <AutocompleteInput label={t("resources.productStatistic.filters.format")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput
            key="filter-license"
            label={t("resources.productStatistic.filters.license")}
            source="licenseUuid"
            reference="license"
        >
            <AutocompleteInput label={t("resources.productStatistic.filters.license")} optionValue="id" optionText="name" />
        </ReferenceInput>,
        <DateInput key="start-date" source="startDate" label={t("resources.productStatistic.filters.startDate")} />,
        <DateInput key="end-date" source="endDate" label={t("resources.productStatistic.filters.endDate")} />,
        <RadioButtonGroupInput
            key="test-account"
            label={t("resources.productStatistic.filters.testAccount")}
            source="testAccount"
            choices={[
                { id: "false", name: "resources.productStatistic.choices.testAccount.false" },
                { id: "true", name: "resources.productStatistic.choices.testAccount.true" }
            ]}
        />
    ];

    return (
        <>
            <List
                pagination={<ListPagination />}
                perPage={20}
                filters={filters}
                filterDefaultValues={{ startDate: formatedStartDate, endDate: formatedEndDate }}
                actions={<ProductStatisticListActions />}
            >
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="title" />
                    <TextField source="codeEm" />
                    <TextField source="codeEmArte" />
                    <TextField source="serie.name" label={t("resources.productStatistic.fields.serie")} />
                    <TextField source="rightHolder.name" label={t("resources.productStatistic.fields.rightHolder")} />
                    <TextField source="contentType.name" label={t("resources.productStatistic.fields.contentType")} />
                    <TextField source="format.name" label={t("resources.productStatistic.fields.format")} />
                    <TextField source="license.name" label={t("resources.productStatistic.fields.license")} />
                    <NumberField source="playCount" label={t("resources.productStatistic.fields.playCount")} />
                    <NumberField source="licenseCount" label={t("resources.productStatistic.fields.licenseCount")} />
                </Datagrid>
            </List>
        </>
    );
}
