/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import {
    ArrayInput,
    AutocompleteInput,
    Button,
    ReferenceInput,
    TabbedForm,
    TextInput,
    required,
    useArrayInput,
    useGetOne,
    useTranslate
} from "react-admin";

import { useCallback, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useInputStyles } from "../../../utils/useInputStyles";

import { SortableList } from "../../../utils/components/sortable/SortableList";
import { TitleTextField } from "../../../common/TitleTextField";
import { ImageListInput } from "../../image/components/Form";
import { CreateToolbar } from "../../../common/CreateToolbar";
import { EditToolbar } from "../../../common/EditToolbar";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { PublicationFormGroup } from "../../../utils/components/form/PublicationFormGroup";

function SortableListSelectInput(props: { optionText: string; targetType: string; setTargetId: (id: any) => void }) {
    return (
        <ReferenceInput source="targetId" reference={props.targetType}>
            <AutocompleteInput onChange={(targetId) => props.setTargetId(targetId)} optionText={props.optionText} optionValue="id" />
        </ReferenceInput>
    );
}

function SortableListItemLabel(props: { targetType: string; targetId: string; labelFieldId: string }) {
    const { data } = useGetOne(props.targetType, { id: props.targetId });

    return <span>{data?.[props.labelFieldId]}</span>;
}

function SortableListAddButton(props: any) {
    const t = useTranslate();
    const [targetType, setTargetType] = useState("selection");
    const [targetId, setTargetId] = useState();

    const onTargetTypeChange = useCallback(
        (event: any) => {
            const newTargetType = event.target.value;

            if (newTargetType != targetType) {
                setTargetType(newTargetType);
                setTargetId(undefined);
            }
        },
        [setTargetType, targetType, setTargetId]
    );

    const onAdd = useCallback(() => {
        props.add({ id: uuidv4(), targetId, targetType });
    }, [targetId, targetType]);

    return (
        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <FormControl
                sx={{
                    marginTop: "4px",
                    width: "12rem",
                    marginRight: "1rem",
                    "& .MuiSelect-select": {
                        paddingTop: "4px"
                    }
                }}
            >
                <Select margin="none" defaultValue={targetType} onChange={onTargetTypeChange}>
                    <MenuItem value="product">{t("resources.homepage.choices.targetType.product")}</MenuItem>
                    <MenuItem value="serie">{t("resources.homepage.choices.targetType.serie")}</MenuItem>
                </Select>
            </FormControl>
            <SortableListSelectInput
                optionText={["selection", "product"].includes(targetType) ? "title" : "name"}
                targetType={targetType}
                setTargetId={setTargetId}
            />
            <Button
                style={{ marginTop: "4px" }}
                variant="contained"
                size="small"
                label={t("resources.homepage.buttons.addLayerItem")}
                onClick={onAdd}
            />
        </div>
    );
}

function SortableListFormIterator(props: any) {
    const t = useTranslate();
    const { append, replace, fields } = useArrayInput(props);
    const [items, setItems] = useState<any[]>(fields);

    const addItem = useCallback(
        (item: any) => {
            append(item);
            items.push(item);
            setItems(items);
        },
        [append, items]
    );

    const removeItem = useCallback(
        (id: string) => {
            const newItems = items.filter((item) => item.id != id);
            replace(newItems);
            setItems(newItems);
        },
        [replace, items]
    );

    return (
        <div style={{ paddingTop: "1rem" }}>
            <SortableListAddButton add={addItem} />
            <div
                style={{
                    width: "100%",
                    padding: "0.5rem 0.2rem",
                    overflowX: "auto"
                }}
            >
                <SortableList
                    items={items}
                    onChange={(updatedItems) => {
                        setItems(updatedItems);
                        replace(updatedItems);
                    }}
                    renderItem={(item) => (
                        <SortableList.Item id={item.id}>
                            <div
                                style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                    minWidth: "20rem"
                                }}
                            >
                                <Chip
                                    color="default"
                                    size="small"
                                    label={t(`resources.homepage.choices.targetType.${item.targetType}`)}
                                    style={{ marginRight: "1rem" }}
                                />
                                <SortableListItemLabel
                                    targetType={item.targetType}
                                    targetId={item.targetId}
                                    labelFieldId={["selection", "product"].includes(item.targetType) ? "title" : "name"}
                                />

                                <div style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)" }}>
                                    <SortableList.DragHandle />
                                </div>
                                <div style={{ position: "absolute", right: "0px", top: "50%", transform: "translateY(-50%)" }}>
                                    <Button
                                        onClick={() => {
                                            removeItem(item.id);
                                        }}
                                        startIcon={<HighlightOffRoundedIcon />}
                                        sx={{
                                            minWidth: "20px",
                                            "& .MuiButton-startIcon": {
                                                margin: "0"
                                            },
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "24px"
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </SortableList.Item>
                    )}
                />
            </div>
        </div>
    );
}

export function SelectionSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");
    const formProps: any = {};

    if (isCreateMode) {
        formProps["toolbar"] = <CreateToolbar />;
    } else {
        // Edit
        formProps["toolbar"] = <EditToolbar />;
    }

    return (
        <TabbedForm {...formProps}>
            <TabbedForm.Tab label="general">
                <TitleTextField type={isCreateMode ? "create" : "edit"} />
                <TextInput sx={classes.inputField} validate={required()} source="title" />
                <TextInput sx={classes.inputField} source="description" fullWidth multiline />
                <ArrayInput source="items">
                    <SortableListFormIterator />
                </ArrayInput>
                <ImageListInput targetType="selection" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="publication">
                <PublicationFormGroup />
            </TabbedForm.Tab>
        </TabbedForm>
    );
}
