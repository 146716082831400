import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { isDefined } from "../../utils/typedLoDashUtils";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import { convertIdFilter } from "../../utils/filterUtils";

import type { ProductDTO } from "./ProductDTO";
import type { ProductRecord } from "./ProductRecord";
import { RightHolderDTO } from "../rightHolder/RightHolderDTO";
import { ContentTypeDTO } from "../contentType/ContentTypeDTO";
import { arrayRange } from "../../utils/array";
import { ContentStatus } from "../../model/content-status";
import { PublicationStatus } from "../../model/publication-status";

const findAllTranslated = fetcher.path("/api/content/product/findAll").method("post").create();
const getTranslated = fetcher.path("/api/content/product/{uuid}").method("get").create();
const createOne = fetcher.path("/api/content/product/").method("post").create();
const updateOne = fetcher.path("/api/content/product/{uuid}").method("put").create();
const deleteOne = fetcher.path("/api/content/product/{uuid}").method("delete").create();
const getAllByUuids = fetcher.path("/api/content/product/allByUuids").method("get").create();

const MAX_PAGE_SIZE = 500;

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns UserRecord
 */

function mapDTOToRecord(dto: ProductDTO): ProductRecord {
    // DTO from backend
    const {
        id = 0,
        uuid = "",
        name = "",
        title = "",
        description = "",
        character,
        contentType,
        rightHolder,
        themes,
        genres,
        season,
        format,
        contentOrigin,
        license,
        status,
        publicationStatus,
        published = false,
        publicationStartedAt = null,
        publicationEndedAt = null,
        codeEm = "",
        codeEmArte = 0,
        codeBnf = "",
        keywords = "",
        serieKeywords = "",
        version = "",
        retranscription = false,
        singleContent = false,
        episodePosition,
        minAge = 0,
        maxAge = 99,
        year = 1000,
        country = "",
        collection = "",
        duration = 0,
        credits = "",
        authors = "",
        directors = "",
        composers = "",
        actors = "",
        producers = "",
        illustrators = "",
        soundContributors = "",
        voiceContributors = ""
    } = dto;

    // Record for front-end
    return {
        shortId: id,
        id: uuid,
        uuid,
        name,
        title,
        description,
        codeEm,
        codeEmArte,
        codeBnf,
        keywords,
        serieKeywords,
        version,
        retranscription,
        singleContent,
        episodePosition,
        minAge,
        maxAge,
        year,
        country,
        duration,
        credits,
        authors,
        directors,
        composers,
        actors,
        producers,
        illustrators,
        soundContributors,
        voiceContributors,
        collection,
        status: status as ContentStatus,
        publicationStatus: publicationStatus as PublicationStatus,
        published,
        publicationStartedAt: publicationStartedAt as string,
        publicationEndedAt: publicationEndedAt as string,
        rightHolder: rightHolder as RightHolderDTO,
        rightHolderId: rightHolder?.["uuid"] as string,
        characterId: character?.["uuid"] as string,
        contentType: contentType as ContentTypeDTO,
        contentTypeId: contentType?.["uuid"] as string,
        themeIds: themes?.map((theme) => theme?.["uuid"] as string) as string[],
        genreIds: genres?.map((genre) => genre?.["uuid"] as string) as string[],
        seasonId: season?.["uuid"] as string,
        formatId: format?.["uuid"] as string,
        contentOriginId: contentOrigin?.["uuid"] as string,
        licenseId: license?.["uuid"] as string
    } as ProductRecord;
}

/**
 * Parses the record from frontend to DTO for backend
 * @param record
 * @returns FormatDTO
 */

function mapRecordToDTO(record: ProductRecord): ProductDTO {
    // Record from front-end
    const {
        shortId = 0,
        id = "",
        name = "",
        title = "",
        description = "",
        codeEm = "",
        codeEmArte = 0,
        codeBnf = "",
        keywords = "",
        serieKeywords = "",
        version = "",
        retranscription = false,
        singleContent = false,
        status = "draft",
        publicationStatus,
        published = false,
        publicationStartedAt = null,
        publicationEndedAt = null,
        episodePosition,
        minAge = 0,
        maxAge = 99,
        year = 1000,
        country = "",
        collection = "",
        duration = 0,
        credits = "",
        authors = "",
        directors = "",
        composers = "",
        actors = "",
        producers = "",
        illustrators = "",
        soundContributors = "",
        voiceContributors = "",
        characterId,
        contentTypeId,
        rightHolderId,
        themeIds,
        genreIds,
        seasonId,
        formatId,
        contentOriginId,
        licenseId
    } = record;

    // DTO for back-end
    return {
        id: shortId,
        uuid: id as string,
        name,
        title,
        description,
        codeEm,
        codeEmArte,
        codeBnf,
        keywords,
        serieKeywords,
        version,
        retranscription,
        singleContent,
        episodePosition,
        minAge,
        maxAge,
        year,
        country,
        collection,
        duration,
        credits,
        authors,
        directors,
        composers,
        actors,
        producers,
        illustrators,
        soundContributors,
        voiceContributors,
        status,
        publicationStatus,
        published,
        publicationStartedAt: publicationStartedAt as string,
        publicationEndedAt: publicationEndedAt as string,
        character: {
            uuid: characterId as string
        },
        contentType: {
            uuid: contentTypeId as string
        },
        rightHolder: {
            uuid: rightHolderId as string
        },
        season:
            seasonId != null
                ? {
                      uuid: seasonId as string
                  }
                : undefined,
        format: {
            uuid: formatId as string
        },
        contentOrigin: {
            uuid: contentOriginId as string
        },
        license: {
            uuid: licenseId as string
        },
        genres: genreIds?.map((genreId: string) => {
            return { uuid: genreId as string };
        }),
        themes: themeIds?.map((themeId: string) => {
            return { uuid: themeId as string };
        })
    } as ProductDTO;
}

interface ProductFilters extends BaseFilters {
    q?: string;
    codeEm?: string;
    seasonUuid?: string;
    serieUuid?: string;
    contentTypeUuid?: string;
    formatUuid?: string;
    rightHolderUuid?: string;
    published?: boolean;
    status: ContentStatus;
    publicationStatus: PublicationStatus;
    rangePublicationDateBegin?: string;
    rangePublicationDateEnd?: string;
}

function convertFilters(filters: ProductFilters): any {
    const newFilters = Object.assign({}, filters) as any;
    return convertIdFilter(newFilters);
}

async function getPage(parameters: FetchArgType<typeof findAllTranslated>) {
    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<ProductRecord>> {
    const { q: searchTerms, ...filters } = params.filter as ProductFilters;

    let parameters: FetchArgType<typeof findAllTranslated> = {
        filter: convertFilters(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    if (isDefined(searchTerms)) {
        parameters = {
            ...parameters,
            filter: { ...parameters.filter, searchTerms }
        };
    }

    const pageable = parameters.pageable;
    const origPageSize = pageable?.size ?? 0;

    if (pageable?.page == 0 && origPageSize > MAX_PAGE_SIZE) {
        // Get total elements
        const newParameters: FetchArgType<typeof findAllTranslated> = {
            filter: parameters.filter ?? {},
            pageable: {
                page: 0,
                size: 1,
                sort: parameters.pageable?.sort ?? []
            }
        };
        const headPage = await getPage(newParameters);
        const newPageSize = Math.min(origPageSize, headPage.total);

        // Use pagination
        const pageIds = arrayRange(pageable?.page, newPageSize / MAX_PAGE_SIZE, 1);

        const pages = await Promise.all(
            pageIds.map((pageId) => {
                const newParameters: FetchArgType<typeof findAllTranslated> = {
                    filter: parameters.filter ?? {},
                    pageable: {
                        page: pageId,
                        size: MAX_PAGE_SIZE,
                        sort: parameters.pageable?.sort ?? []
                    }
                };
                return getPage(newParameters);
            })
        );

        const allData = [];

        for (const page of pages) {
            allData.push(...page.data);
        }

        return {
            data: allData.slice(0, newPageSize),
            total: pages?.[0]?.total ?? 0
        };
    }

    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };
}

async function getOne(params: LangParams | (number | string)): Promise<ProductRecord> {
    const {
        data: { content: userDTO }
    } = await getTranslated({
        uuid: params as string
    });
    assertIsDefined(userDTO);

    return mapDTOToRecord({ id: userDTO.id ?? 0, ...userDTO });
}

async function getMany(uuids: readonly (number | string)[]): Promise<GetManyResult<ProductRecord>> {
    const {
        data: { content: userDTO }
    } = await getAllByUuids({
        uuids: uuids as string[]
    });
    assertIsDefined(userDTO);

    return { data: await Promise.all(userDTO.map((dto) => mapDTOToRecord({ id: dto.id ?? 0, ...dto }))) };
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<ProductRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(params: DeepReadonly<UpdateParams<ProductRecord>>): Promise<UpdateResult<ProductRecord>> {
    const payload = mapRecordToDTO(params.data as ProductRecord);

    const parameters = { uuid: payload.uuid ?? "", ...payload };

    const { data } = await updateOne(parameters);
    const userDTO = data.content;
    assertIsDefined(userDTO);

    return { data: mapDTOToRecord({ id: parameters.id, ...userDTO }) };
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(params: DeepReadonly<CreateParams>): Promise<CreateResult<ProductRecord>> {
    const payload = mapRecordToDTO(params.data as any);

    assertIsDefined(payload.name);
    const parameters = { ...payload };
    delete (parameters as any)["id"];
    delete (parameters as any)["uuid"];
    const { data } = await createOne(parameters);
    const productDTO = data.content;

    assertIsDefined(productDTO);

    return { data: mapDTOToRecord({ id: productDTO.id as number, ...productDTO }) };
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(uuid: number | string): Promise<ProductRecord> {
    const {
        data: { content: isDeleted }
    } = await deleteOne({
        uuid: uuid as string
    });
    assertIsDefined(isDeleted);

    return {} as ProductRecord;
}

async function deleteMany(params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    await Promise.all(params.ids.map((id: string) => _delete(id)));
    return {
        data: params.ids.map((id: string) => {
            id;
        })
    };
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<ProductRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const ProductDataProvider: MpmDataProvider<ProductRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne,
    update,
    updateMany
};
