import dayjs from "dayjs";
import { DateInput, RadioButtonGroupInput, useInput } from "react-admin";

export const PublicationFormGroup = () => {
    const startedAtInput = useInput({ source: "publicationStartedAt" });
    const endedAtInput = useInput({ source: "publicationEndedAt" });

    const handleChange = (event: any) => {
        const status = event.target.value;
        const nowDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0).toDate().getTime();

        if (status === "publishable") {
            startedAtInput.field.onChange(nowDate);
            endedAtInput.field.onChange(null);
        } else {
            if (startedAtInput.field.value != null) {
                endedAtInput.field.onChange(nowDate);
            }
        }
    };

    return (
        <>
            <RadioButtonGroupInput
                source="status"
                onChange={handleChange}
                choices={[
                    { id: "draft", name: "resources.common.choices.contentStatus.draft" },
                    { id: "publishable", name: "resources.common.choices.contentStatus.publishable" }
                ]}
            />
            <DateInput source="publicationStartedAt" />
            <DateInput source="publicationEndedAt" />
        </>
    );
};
