import { Datagrid, DateField, EditButton, List, ShowButton, TextField, TextInput, useTranslate } from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import { SelectionListActions } from "./components/SelectionListActions";
import { ContentStatusField } from "../../utils/components/ContentStatusField";
import { PublicationStatusField } from "../../utils/components/PublicationStatusField";
// import { ContentStatusFilter } from "../../utils/components/form/ContentStatusFilter";
// import { PublicationStatusFilter } from "../../utils/components/form/PublicationStatusFilter";

export function SelectionList(): JSX.Element {
    const t = useTranslate();

    const filters = [
        <TextInput key="searchTerms-filter" label={t("resources.selection.fields.title")} source="searchTerms" alwaysOn />
        // <ContentStatusFilter source="status" label={t("resources.common.filters.status")} />,
        // <PublicationStatusFilter source="publicationStatus" label={t("resources.common.filters.publicationStatus")} />,
    ];

    return (
        <>
            <List pagination={<ListPagination />} perPage={20} filters={filters} actions={<SelectionListActions />}>
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="title" />
                    <TextField source="description" />
                    <ContentStatusField source="status" label={t("resources.common.fields.status")} />
                    <PublicationStatusField source="publicationStatus" label={t("resources.common.fields.publicationStatus")} />
                    <DateField label={t("resources.selection.fields.publicationStartedAt")} source="publicationStartedAt" />
                    <DateField label={t("resources.selection.fields.publicationEndedAt")} source="publicationEndedAt" />
                    <DateField label={t("resources.selection.fields.createdAt")} source="createdAt" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
        </>
    );
}
