/* eslint-disable max-lines */

import polyglotI18nProvider from "ra-i18n-polyglot";
import type { TranslationMessages } from "ra-core";
import includes from "lodash/includes";
import head from "lodash/head";
import { assert } from "../utils/assertions";

// React Admin Interface translations
import frenchMessages from "ra-language-french";

/*
 * DO list here the locale codes that are translated (only 'fr' as of today)
 * DO NOT use this array elsewhere in the code, only here to _define the TranslatedLocale type_.
 *
 * Note: The first listed locale will be the default one.
 */
const TRANSLATED_LOCALES = ["fr"] as const;

type TranslatedLocale = typeof TRANSLATED_LOCALES[number];

// Do use this array in code below
const translatedLocales: readonly TranslatedLocale[] = TRANSLATED_LOCALES;

function isTranslatedLocale(maybeTranslatedLocale: unknown): maybeTranslatedLocale is TranslatedLocale {
    return includes(translatedLocales, maybeTranslatedLocale);
}

const messages: Record<TranslatedLocale, TranslationMessages> = {
    fr: {
        ...frenchMessages,
        action: {
            createAndGoToList: "Créer et retourner à la liste",
            createAndAdd: "Créer et ajouter",
            update: "Mettre à jour",
            updateAndClose: "Mettre à jour et fermer",
            updateSuccess: "Mise à jour réussie",

            deleteMediaTitle: "Supprimer le média",
            deleteMediaConfirm:
                "Etes-vous sûr de vouloir supprimer ce média ? La suppression est irréversible. Cette page sera rafraichie après suppression, toute donnée en cours de modification sera perdue."
        },
        autocompleteArrayInput: {
            noOptions: "Aucune option"
        },
        dialog: {
            downloadTitle: "Téléchargement en cours...",
            downloadContent: "Veuillez patienter, cela peut prendre jusqu'à plusieurs dizaines de secondes.",
            exportByMail: "Votre export est en cours de génération, il vous sera envoyé par email"
        },
        notification: {
            updated: "Element mis à jour",
            downloadInProgress: "Téléchargement du média en cours...",
            updatedMediaAndRefresh: "Média mis à jour, rafraichissement en cours...",
            deletedElement: "Element supprimé",
            deletedMediaAndRefresh: "Média supprimé, rafraichissement en cours..."
        },
        resources: {
            common: {
                fields: {
                    id: "Identifiant",
                    status: "Etat",
                    publicationStatus: "Statut"
                },
                filters: {
                    status: "Etat",
                    publicationStatus: "Statut"
                },
                choices: {
                    contentStatus: {
                        draft: "Brouillon",
                        publishable: "Publiable"
                    },
                    publicationStatus: {
                        unpublished: "Hors ligne",
                        published: "En ligne",
                        "to-be-published": "A venir"
                    }
                }
            },
            administrativeEntity: {
                name: "Entité Administrative |||| Entités Administratives",
                fields: {
                    name: "Nom",
                    numberOfCreditsLeft: "Nombre de crédits restants",
                    numberOfUsedCredits: "Nombre de crédits utilisés",
                    reference: "Référence"
                }
            },
            artist: {
                name: "Artiste |||| Artistes",
                create: "Créer un nouvel artiste:",
                edit: "Éditer l'artiste suivant:",
                fields: {
                    fullName: "Nom complet"
                }
            },
            image: {
                name: "Image |||| Images",
                upload: "Ajouter une image",
                create: "Créer une nouvelle image:",
                edit: "Éditer l'image:",
                fields: {
                    type: "Type",
                    url: "Image"
                },
                choices: {
                    type: {
                        thumbnail: "Vignette",
                        banner: "Bannière"
                    }
                }
            },
            contractReportByContract: {
                name: "Rapport 1",
                fields: {
                    administrativeEntityReference: "Administrative entity ref",
                    administrativeEntityName: "Administrative entity name",
                    contractReference: "Contract ref",
                    sumValidConsumptions: "Number of consumptions",
                    sumInvalidConsumptions: "Number of invalid consumptions",
                    sumTotalConsumptions: "Total",
                    unitPrice: "Unit price (excl tax)",
                    totalCreditsUsed: "Credits used",
                    sumCinetekConsumptions: "LaCinetek",
                    sumCampusConsumptions: "Arte Campus",
                    sumArteCredits: "ARTE",
                    sumLmcCredits: "LMC",
                    sumTv5Credits: "TV5",
                    productName: "Nom du film",
                    sumValid: "Nombre de crédits",
                    sumInvalid: "Nombre de crédits invalides",
                    sumTotal: "Total",
                    creditsUsed: "Crédits utilisés",
                    turnOver: "Turnover (excl tax)",
                    sumCinetek: "LaCinetek",
                    sumCampus: "Arte Campus",
                    sumArte: "ARTE",
                    sumLmc: "LMC",
                    sumTv5: "TV5"
                }
            },
            contractReportByMovie: {
                name: "Rapport 2",
                fields: {
                    movieFilmCode: "Code du film",
                    productName: "Nom du film",
                    validCredits: "Crédits valides",
                    canceledCredits: "Crédits annulés",
                    unitPrice: "Prix unitaire",
                    creditsUsed: "Crédits utilisés",
                    turnOver: "Turnover",
                    administrativeEntityReference: "Référence bibliothèque",
                    contractReference: "Référence contrat",
                    sumCinetek: "LaCinetek",
                    sumCampus: "Arte Campus",
                    sumArte: "ARTE",
                    sumLmc: "LMC",
                    sumTv5: "TV5"
                }
            },
            contentType: {
                name: "Type de contenu |||| Types de contenu",
                create: "Créer un nouveau type de contenu:",
                edit: "Éditer un type de contenu:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            format: {
                name: "Format |||| Formats",
                create: "Créer un nouveau format:",
                edit: "Éditer un format:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            theme: {
                name: "Thème |||| Thèmes",
                create: "Créer un nouveau thème:",
                edit: "Éditer un thème:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            character: {
                name: "Personnage |||| Personnages",
                create: "Créer un nouveau personnage:",
                edit: "Editer le personnage suivant:",
                fields: {
                    name: "Nom",
                    description: "Description",
                    images: "Images",
                    image: "Image",
                    imageType: "Type d'image"
                }
            },
            genre: {
                name: "Genre |||| Genres",
                create: "Créer un nouveau genre:",
                edit: "Editer le genre suivant:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            license: {
                name: "Licence |||| Licences",
                create: "Créer une nouvelle licence:",
                edit: "Éditer une licence:",
                fields: {
                    name: "Nom",
                    title: "Titre",
                    description: "Description",
                    orderId: "N° de commande"
                }
            },
            contentOrigin: {
                name: "Origine du contenu |||| Origines du contenu",
                create: "Créer une origine de contenu:",
                edit: "Editer l'origine de contenu:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            rightHolder: {
                name: "Propriétaire des droit |||| Propriétaires des droits",
                create: "Créer un nouvel ayant droit:",
                edit: "Editer l'ayant droit suivant:",
                fields: {
                    name: "Nom",
                    description: "Description"
                }
            },
            homepage: {
                name: "Page d'accueil |||| Pages d'accueil",
                create: "Créer une nouvelle page d'accueil:",
                edit: "Editer la page d'accueil:",
                fields: {
                    title: "Titre",
                    description: "Description",
                    layers: "Blocs",
                    selectionLayerType: "Bloc de type sélection",
                    textLayerType: "Bloc de type texte",
                    itemListLayerType: "Bloc de type liste d'éléments",
                    highlightLayerType: "Bloc de type mise en avant",
                    itemDisplayType: "Type d'affichage des éléments",
                    targetType: "Type de contenu"
                },
                buttons: {
                    addLayer: "Ajouter un bloc",
                    addLayerItem: "Ajouter un élément"
                },
                choices: {
                    itemDisplayType: {
                        thumbnail: "Vignette",
                        banner: "Bannière"
                    },
                    layerType: {
                        selection: "Sélection",
                        text: "Texte",
                        itemList: "Liste d'éléments",
                        highlight: "Mise en avant"
                    },
                    targetType: {
                        product: "Produit",
                        serie: "Série",
                        character: "Personnage",
                        selection: "Sélection"
                    }
                }
            },
            selection: {
                name: "Sélection |||| Sélections",
                create: "Créer une nouvelle sélection:",
                edit: "Editer la selection suivante:",
                fields: {
                    title: "Titre",
                    description: "Description",
                    targetType: "Type de contenu",
                    published: "Publié ?",
                    publicationStartedAt: "Date de début de publication",
                    publicationEndedAt: "Date de fin de publication",
                    createdAt: "Date de création"
                },
                choices: {
                    targetType: {
                        product: "Produit",
                        serie: "Série"
                    }
                }
            },
            contentViewStatistic: {
                name: "Consultations par contenu"
            },
            subscriberViewStatistic: {
                name: "Consultations par inscrit"
            },
            adminUser: {
                name: "Administrateur |||| Administrateurs",
                create: "Créer un nouvel administrateur:",
                edit: "Editer l'administrateur suivant:",
                fields: {
                    username: "Login",
                    email: "Email",
                    firstName: "Prénom",
                    lastName: "Nom",
                    password: "Mot de passe",
                    confirmedPassword: "Confirmation du mot de passe",
                    enabled: "Activé ?",
                    lastLogin: "Dernière connexion"
                },
                filters: {
                    text: "Login ou email"
                },
                validation: {
                    passwordConfirmationError: "Le mot de passe n'est pas confirmé"
                }
            },
            episode: {
                name: "Épisode |||| Épisodes",
                fields: {
                    creationDate: "Date d'ajout",
                    colorType: "Couleur",
                    deletionDate: "Date de dépublication",
                    isActive: "Publié",
                    isOutOfCatalog: "Hors Catalogue",
                    publicationDate: "Date de publication",
                    shortId: "Identifiant",
                    title: "Titre",
                    releaseDate: "Date de sortie en salles",
                    duration: "Durée (en secondes)",
                    summary: "Synopsis",
                    partner: "Partenaire",
                    rating: "Audience",
                    productTags: "Thèmes",
                    storyCountries: "Pays de l'intrigue",
                    audioLanguages: "Langues audio",
                    streamingCode: "Code streaming",
                    ucdbCode: "Code ucdb",
                    isEpisode: "Est un épisode",
                    originalTitle: "Titre original",
                    productionYear: "Année de production",
                    productionCountriesIds: "Pays de production",
                    genreSubGenre: "Genre / Sous-genre",
                    screenAgreement: "Contrat d'exploitation",
                    streamingType: "Ayant-droit"
                }
            },
            home: {
                name: "Page d'accueil |||| Pages d'accueil",
                create: "Créer une nouvelle page d'accueil:",
                edit: "Editer la page d'accueil suivante:",
                fields: {
                    type: "Type",
                    product: "Film",
                    label: "Label",
                    elements: "Éléments",
                    administrativeEntityWording: "Message d'accueil (700 caractères max.)"
                },
                blockLabel: {
                    NEWS: "Nouveautés",
                    SLIDE: "Carrousel",
                    CINETEK: "LaCinetek",
                    SELECTION: "Sélection"
                },
                blockOrigin: {
                    mednum: "Édito Médiathèque Numérique",
                    personalized: "Édito personnalisé"
                },
                empty: "Vous n'avez pas configuré l'éditorialisation de la page d'accueil. C'est actuellement l'éditorialisation de l'équipe Médiathèque Numérique qui s'affiche par défaut. En cliquant sur créer, vous pourrez éditer et modifier la page d'accueil du service.",
                reset: "Réinitialiser",
                resetSuccess: "La page d'accueil a été réinitialisée avec succès.",
                resetFailure: "La réinitialisation a échoué",
                successUpdate: "Page d'accueil éditée",
                homeName: "Nom de la page d'accueil",
                deleteSuccess: "Page d'accueil supprimée",
                editCarouselProducts: "Editer les produits dans le carousel"
            },
            media: {
                name: "Média |||| Médias",
                maxUploadSize: "Attention : La taille maximale de l'upload ne peut dépasser 1Go",
                fields: {
                    image: "Miniature",
                    type: "Type",
                    finalUrl: "Url"
                }
            },
            movieReportAggregation: {
                name: "Consommation par film",
                fields: {
                    rank: "Rank",
                    productName: "Film",
                    sumValid: "Nombre de crédits",
                    sumInvalid: "Nombre de crédits invalides",
                    sumTotal: "Total",
                    sumCinetek: "LaCinetek",
                    sumCampus: "Arte Campus",
                    sumArte: "ARTE",
                    sumLmc: "LMC",
                    sumTv5: "TV5",
                    sumValidCredits: "Number of consumptions",
                    sumInvalidCredits: "Number of invalid consumptions",
                    sumTotalCredits: "Total",
                    sumCinetekConsumptions: "LaCinetek",
                    sumCampusConsumptions: "Arte Campus",
                    sumArteCredits: "ARTE",
                    sumLmcCredits: "LMC",
                    sumTv5Credits: "TV5"
                }
            },
            product: {
                name: "Produit |||| Produits",
                create: "Créer un nouveau produit:",
                edit: "Éditer un produit:",
                tabs: {
                    technicalInfo: "Info technique"
                },
                fields: {
                    name: "Nom",
                    title: "Titre",
                    keywords: "Mots clés",
                    serieKeywords: "Mots clés de la série",
                    genres: "Genres",
                    themes: "Thèmes",
                    description: "Description",
                    singleContent: "Contenu seul",
                    serie: "Série",
                    season: "Saison",
                    rightHolder: "Ayant droit",
                    contentType: "Type de contenu",
                    country: "Pays",
                    year: "Année",
                    duration: "Durée (en min.)",
                    version: "Version",
                    retranscription: "Retranscription",
                    credits: "Crédits",
                    authors: "Auteurs",
                    directors: "Réalisateurs",
                    actors: "Acteurs",
                    producers: "Producteurs",
                    composers: "Compositeurs",
                    illustrators: "Illustrateurs",
                    soundContributors: "Son",
                    voiceContributors: "Voix",
                    minAge: "Age minimum",
                    maxAge: "Age maximum",
                    published: "Publié ?",
                    publicationStartedAt: "Date de début de publication",
                    publicationEndedAt: "Date de fin de publication",
                    contentOrigin: "Origine",
                    character: "Personnage",
                    license: "Licence",
                    episodePosition: "Position de l'épisode"
                },
                filters: {
                    codeEm: "Code Em",
                    codeEmArte: "Code Em Arte",
                    contentType: "Type de contenu",
                    format: "Format",
                    rightHolder: "Ayant droit",
                    rangePublicationDateBegin: "Date de publication - Début",
                    rangePublicationDateEnd: "Date de publication - Fin"
                }
            },
            season: {
                name: "Saison |||| Saisons",
                create: "Créer une nouvelle saison:",
                edit: "Éditer une saison:",
                episodeList: "Episodes",
                fields: {
                    name: "Nom",
                    description: "Description",
                    serie: "Série",
                    published: "Publié ?",
                    publicationStartedAt: "Date de début de publication",
                    publicationEndedAt: "Date de fin de publication"
                }
            },
            serie: {
                name: "Série |||| Séries",
                create: "Créer une nouvelle série:",
                edit: "Éditer une série:",
                episodeList: "Episodes",
                seasonList: "Saisons",
                fields: {
                    name: "Nom",
                    description: "Description",
                    published: "Publié ?",
                    publicationStartedAt: "Date de début de publication",
                    publicationEndedAt: "Date de fin de publication"
                }
            },
            productStatistic: {
                name: "Visionnages par produit",
                fields: {
                    code: "Code",
                    name: "Nom",
                    title: "Titre",
                    season: "Saison",
                    serie: "Série",
                    genres: "Genres",
                    rightHolder: "Ayant-droit",
                    contentType: "Type de contenu",
                    format: "Format",
                    license: "Licence",
                    minAge: "Âge min",
                    maxAge: "Âge max",
                    genre: "Genre",
                    licenseDuration: "Durée de licence du produit",
                    publicationStartDate: "Date de publication",
                    publicationEndDate: "Date de dépublication",
                    playCount: "Nb de visionnages",
                    licenseCount: "Nb de licences activées",
                    createdAt: "Date de dernière consommation",
                    lastConsumptionDate: "Date de dernière consommation"
                },
                filters: {
                    codeEm: "Code EM",
                    codeEmArte: "Code Em Arte",
                    contentType: "Type de contenu",
                    format: "Format",
                    rightHolder: "Ayant-droit",
                    license: "Licence",
                    startDate: "Date de début",
                    endDate: "Date de fin",
                    testAccount: "Compte de test"
                },
                choices: {
                    testAccount: {
                        false: "Sans les comptes de test",
                        true: "Uniquement les comptes de test"
                    }
                }
            },
            childStatistic: {
                name: "Visionnages par inscrit",
                fields: {
                    childId: "Utilisateur",
                    playCount: "Nb de visionnages",
                    licenseCount: "Nb de licences activées",
                    createdAt: "Date de dernière consommation",
                    lastConsumptionDate: "Date de dernière consommation"
                },
                filters: {
                    startDate: "Date de début",
                    endDate: "Date de fin",
                    testAccount: "Compte de test"
                },
                choices: {
                    testAccount: {
                        false: "Sans les comptes de test",
                        true: "Uniquement les comptes de test"
                    }
                }
            },
            staticPage: {
                name: "Page statique |||| Pages statiques",
                create: "Créer une nouvelle page statique:",
                edit: "Éditer une page statique:",
                fields: {
                    code: "Code",
                    title: "Titre",
                    description: "Description",
                    content: "Contenu de la page"
                }
            },
            user: {
                name: "Utilisateurs",
                fields: {
                    username: "Nom d'utilisateur",
                    firstName: "Prénom",
                    lastName: "Nom",
                    email: "Email",
                    lastLogin: "Dernière connexion",
                    contributor: "Contributeur",
                    test: "Test",
                    uuid: "Uuid"
                }
            },
            userComment: {
                name: "Commentaire |||| Commentaires",
                edit: "Editer le commentaire suivant:",
                fields: {
                    content: "Contenu",
                    rating: "Notation",
                    lastModificationTimestamp: "Dernière date de modifications",
                    product: "Produit",
                    user: "Utilisateur",
                    reportedAsAbuse: "Signalé",
                    authorId: "Auteur",
                    email: "Email"
                }
            },
            userTransactions: {
                name: "Transactions",
                refund: "Refund",
                fields: {
                    userName: "Nom de l'utilisateur",
                    productName: "Nom du produit",
                    bundleName: "Nom des épisodes",
                    parentProductName: "Nom du pack",
                    administrativeEntityName: "Entité administrative",
                    creationDate: "Date de création"
                }
            }
        }
    }
} as const;

function getMessages(locale: string): TranslationMessages {
    assert(isTranslatedLocale(locale));
    return messages[locale];
}

export const i18nProvider = polyglotI18nProvider(getMessages, head(translatedLocales), {
    allowMissing: true
});

export const currentLanguages = ["en", "fr"];
