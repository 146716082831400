import { TopToolbar, CreateButton, ExportButton, FilterButton } from "react-admin";
import type { Exporter } from "react-admin";
import { i18nProvider } from "../../../i18n";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "../../../utils/csv";

const EXPORT_FORBIDDEN_KEYS = ["shortId", "uuid", "rightHolderId", "contentTypeId", "seasonId", "serieId", "formatId", "licenseId"];

const productStatisticExporter: Exporter = async (data, _fetchRelatedRecords, __, resource: any) => {
    // Remove useless keys
    for (const line of data) {
        line["serie"] = line?.serie?.name ?? "";
        line["season"] = line?.season?.name ?? "";
        line["format"] = line?.format?.name ?? "";
        line["license"] = line?.license?.name ?? "";
        line["contentType"] = line?.contentType?.name ?? "";
        line["rightHolder"] = line?.rightHolder?.name ?? "";
        line["genres"] = (line?.genres ?? []).map((genre: any) => genre?.name ?? "");

        for (const key of Object.keys(line)) {
            if (!EXPORT_FORBIDDEN_KEYS.includes(key)) {
                continue;
            }
            delete line[key];
        }
    }

    // Get headers
    const firstLine = data?.[0];
    const headers = Object.keys(firstLine);

    // Translate headers
    const rename = [];

    for (const headerId of headers) {
        const defaultTranslation = i18nProvider.translate(`resources.${resource}.fields.${headerId}`, { _: headerId });
        rename.push(i18nProvider.translate(`resources.${resource}.exportFields.${headerId}`, { _: defaultTranslation }));
    }

    return jsonExport(data, { rowDelimiter: ";", headers, rename }, (_err: any, csv: any) => downloadCSV(csv, resource));
};

export function ProductStatisticListActions(): JSX.Element {
    return (
        <TopToolbar sx={{ alignItems: "center" }}>
            <CreateButton />
            <ExportButton maxResults={10000} exporter={productStatisticExporter} />
            <FilterButton disableSaveQuery />
        </TopToolbar>
    );
}
