/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable new-cap */
/* eslint-disable max-lines */

// cSpell: ignore serie, edito, Éditorial, MEDIATHECAIRE, SUPERADMIN
import Apartment from "@mui/icons-material/Apartment";
import Category from "@mui/icons-material/Category";
import Dashboard from "@mui/icons-material/Dashboard";
import Help from "@mui/icons-material/Help";
import Image from "@mui/icons-material/Image";

import ListAlt from "@mui/icons-material/ListAlt";
import LocalOffer from "@mui/icons-material/LocalOffer";
import Movie from "@mui/icons-material/Movie";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import type SvgIcon from "@mui/material/SvgIcon";
import type { ResourceProps } from "react-admin";

import type { MpmRecord, MpmDataProvider } from "../data";
import { assert } from "../utils/assertions";

import { AdminUserCreate, AdminUserDataProvider, AdminUserEdit, AdminUserList, AdminUserRecord, AdminUserShow } from "./adminUser";

import { ArtistCreate, ArtistDataProvider, ArtistEdit, ArtistList, ArtistRecord, ArtistShow } from "./artist";

import { ProductCreate, ProductDataProvider, ProductEdit, ProductList, ProductRecord, ProductShow } from "./product";

import { LicenseCreate, LicenseDataProvider, LicenseEdit, LicenseList, LicenseRecord, LicenseShow } from "./license";

// import { FormatCreate, FormatDataProvider, FormatEdit, FormatList, FormatRecord, FormatShow } from "./format";

import { CharacterCreate, CharacterDataProvider, CharacterEdit, CharacterList, CharacterRecord, CharacterShow } from "./character";

import { SerieDataProvider, SerieList, SerieRecord, SerieShow, SerieCreate, SerieEdit } from "./serie";

import { SelectionDataProvider, SelectionList, SelectionRecord, SelectionShow, SelectionCreate, SelectionEdit } from "./selection";

import { StaticPageDataProvider, StaticPageList, StaticPageRecord, StaticPageShow, StaticPageCreate, StaticPageEdit } from "./staticPage";

import { ProductStatisticList, ProductStatisticDataProvider, ProductStatisticRecord } from "./productStatistic";
import { ChildStatisticList, ChildStatisticDataProvider, ChildStatisticRecord } from "./childStatistic";

import {
    ContentTypeCreate,
    ContentTypeDataProvider,
    ContentTypeEdit,
    ContentTypeList,
    ContentTypeRecord,
    ContentTypeShow
} from "./contentType";

import { GenreDataProvider, GenreEdit, GenreCreate, GenreList, GenreRecord, GenreShow } from "./genre";

import { ThemeCreate, ThemeDataProvider, ThemeEdit, ThemeShow, ThemeList, ThemeRecord } from "./theme";

import {
    RightHolderCreate,
    RightHolderDataProvider,
    RightHolderEdit,
    RightHolderList,
    RightHolderRecord,
    RightHolderShow
} from "./rightHolder";

import {
    ContentOriginDataProvider,
    ContentOriginCreate,
    ContentOriginEdit,
    ContentOriginList,
    ContentOriginRecord,
    ContentOriginShow
} from "./contentOrigin";

import { ImageRecord, ImageDataProvider } from "./image";

import { SeasonCreate, SeasonEdit, SeasonDataProvider, SeasonList, SeasonRecord, SeasonShow } from "./season";

import { HomeDataProvider, HomeRecord, HomeList, HomeCreate, HomeEdit, HomeShow } from "./home";
import { FormatDataProvider, FormatRecord } from "./format";

export interface ParentMenu {
    name: ParentName;
    label: string;
    to: string;
    icon?: typeof SvgIcon;
    permissions: Role[];
}

type ParentName = "content" | "legal" | "editorial" | "statistic" | "admin";

export type Role =
    | "ALL_ROLES"
    | "ROLE_ADMIN"
    | "ROLE_ADMINISTRATIVE_ENTITY"
    | "ROLE_ENTITY_MANAGER"
    | "ROLE_MEDIATHECAIRE"
    | "ROLE_SUPERADMIN"
    | "ROLE_USER";

type MpmResourceProps = Pick<ResourceProps, "create" | "edit" | "icon" | "list" | "show"> & {
    parentMenu?: ParentName;
    permissions?: Role[];
};

export class MpmResource<TRecord extends MpmRecord> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static readonly MAP: Map<string, MpmResource<any>> = new Map();

    private static readonly PARENT_MENUS: ParentMenu[] = [
        { name: "content", to: "/product", label: "Contenu", icon: Dashboard, permissions: ["ALL_ROLES"] },
        { name: "legal", to: "/page", label: "Juridique", icon: ListAlt, permissions: ["ALL_ROLES"] },
        { name: "editorial", to: "/faq", label: "Editorial", icon: Help, permissions: ["ALL_ROLES"] },
        { name: "statistic", to: "/faq", label: "Statistiques", icon: Help, permissions: ["ALL_ROLES"] },
        { name: "admin", to: "/faq", label: "Administration", icon: Help, permissions: ["ALL_ROLES"] }
    ];

    public static readonly PRODUCT: MpmResource<ProductRecord> = new MpmResource<ProductRecord>("product", ProductDataProvider, {
        list: ProductList,
        show: ProductShow,
        edit: ProductEdit,
        create: ProductCreate,
        icon: Movie,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly SERIE: MpmResource<SerieRecord> = new MpmResource<SerieRecord>("serie", SerieDataProvider, {
        list: SerieList,
        show: SerieShow,
        create: SerieCreate,
        edit: SerieEdit,
        icon: Movie,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly SEASON: MpmResource<SeasonRecord> = new MpmResource<SeasonRecord>("season", SeasonDataProvider, {
        list: SeasonList,
        show: SeasonShow,
        create: SeasonCreate,
        edit: SeasonEdit,
        icon: Movie,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly FORMAT: MpmResource<FormatRecord> = new MpmResource<FormatRecord>("format", FormatDataProvider, {
        // list: FormatList,
        // show: FormatShow,
        // create: FormatCreate,
        // edit: FormatEdit,
        // icon: Language,
        // parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly CONTENT_TYPE: MpmResource<ContentTypeRecord> = new MpmResource<ContentTypeRecord>(
        "contentType",
        ContentTypeDataProvider,
        {
            list: ContentTypeList,
            show: ContentTypeShow,
            create: ContentTypeCreate,
            edit: ContentTypeEdit,
            icon: Category,
            parentMenu: "content",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly THEME: MpmResource<ThemeRecord> = new MpmResource<ThemeRecord>("theme", ThemeDataProvider, {
        list: ThemeList,
        show: ThemeShow,
        create: ThemeCreate,
        edit: ThemeEdit,
        icon: LocalOffer,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly CHARACTER: MpmResource<CharacterRecord> = new MpmResource<CharacterRecord>("character", CharacterDataProvider, {
        list: CharacterList,
        show: CharacterShow,
        create: CharacterCreate,
        edit: CharacterEdit,
        icon: Apartment,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly IMAGE: MpmResource<ImageRecord> = new MpmResource<ImageRecord>("image", ImageDataProvider, {
        permissions: ["ALL_ROLES"]
    });

    public static readonly GENRE: MpmResource<GenreRecord> = new MpmResource<GenreRecord>("genre", GenreDataProvider, {
        list: GenreList,
        show: GenreShow,
        create: GenreCreate,
        edit: GenreEdit,
        icon: SupervisorAccount,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly ARTIST: MpmResource<ArtistRecord> = new MpmResource<ArtistRecord>("artist", ArtistDataProvider, {
        list: ArtistList,
        show: ArtistShow,
        create: ArtistCreate,
        edit: ArtistEdit,
        icon: SupervisorAccount,
        parentMenu: "content",
        permissions: ["ALL_ROLES"]
    });

    public static readonly LICENSE: MpmResource<LicenseRecord> = new MpmResource<LicenseRecord>("license", LicenseDataProvider, {
        list: LicenseList,
        edit: LicenseEdit,
        show: LicenseShow,
        create: LicenseCreate,
        icon: Image,
        parentMenu: "legal",
        permissions: ["ALL_ROLES"]
    });

    public static readonly CONTENT_ORIGIN: MpmResource<ContentOriginRecord> = new MpmResource<ContentOriginRecord>(
        "contentOrigin",
        ContentOriginDataProvider,
        {
            list: ContentOriginList,
            show: ContentOriginShow,
            create: ContentOriginCreate,
            edit: ContentOriginEdit,
            icon: Image,
            parentMenu: "legal",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly RIGHT_HOLDER: MpmResource<RightHolderRecord> = new MpmResource<RightHolderRecord>(
        "rightHolder",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        RightHolderDataProvider,
        {
            list: RightHolderList,
            show: RightHolderShow,
            create: RightHolderCreate,
            edit: RightHolderEdit,
            icon: Image,
            parentMenu: "legal",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly HOMEPAGE: MpmResource<HomeRecord> = new MpmResource<HomeRecord>("homepage", HomeDataProvider, {
        list: HomeList,
        edit: HomeEdit,
        show: HomeShow,
        create: HomeCreate,
        icon: Image,
        parentMenu: "editorial",
        permissions: ["ALL_ROLES"]
    });

    public static readonly SELECTION: MpmResource<SelectionRecord> = new MpmResource<SelectionRecord>("selection", SelectionDataProvider, {
        list: SelectionList,
        edit: SelectionEdit,
        show: SelectionShow,
        create: SelectionCreate,
        icon: Image,
        parentMenu: "editorial",
        permissions: ["ALL_ROLES"]
    });

    public static readonly STATIC_PAGE: MpmResource<StaticPageRecord> = new MpmResource<StaticPageRecord>(
        "staticPage",
        StaticPageDataProvider,
        {
            list: StaticPageList,
            edit: StaticPageEdit,
            show: StaticPageShow,
            create: StaticPageCreate,
            icon: Image,
            parentMenu: "editorial",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly PRODUCT_STATISTIC: MpmResource<ProductStatisticRecord> = new MpmResource<ProductStatisticRecord>(
        "productStatistic",
        ProductStatisticDataProvider,
        {
            list: ProductStatisticList,
            icon: Image,
            parentMenu: "statistic",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly CHILD_STATISTIC: MpmResource<ChildStatisticRecord> = new MpmResource<ChildStatisticRecord>(
        "childStatistic",
        ChildStatisticDataProvider,
        {
            list: ChildStatisticList,
            icon: Image,
            parentMenu: "statistic",
            permissions: ["ALL_ROLES"]
        }
    );

    public static readonly ADMIN_USER: MpmResource<AdminUserRecord> = new MpmResource<AdminUserRecord>("adminUser", AdminUserDataProvider, {
        list: AdminUserList,
        show: AdminUserShow,
        edit: AdminUserEdit,
        create: AdminUserCreate,
        icon: Image,
        parentMenu: "admin",
        permissions: ["ALL_ROLES"]
    });

    public readonly name: string;

    public readonly dataProvider: MpmDataProvider<TRecord>;

    public readonly props: MpmResourceProps;

    private constructor(name: string, dataProvider: Readonly<MpmDataProvider<TRecord>>, props: MpmResourceProps) {
        assert(!MpmResource.MAP.has(name), `There is already a resource registered with the name '${name}'`);
        this.name = name;
        this.dataProvider = dataProvider;
        this.props = props;
        MpmResource.MAP.set(this.name, this);
    }

    public static fromName<TRecord extends MpmRecord>(name: string): MpmResource<TRecord> | undefined {
        return MpmResource.MAP.get(name);
    }

    public static getAllNames(): string[] {
        return Array.from(MpmResource.MAP.keys());
    }

    public static getAll<TRecord extends MpmRecord>(): MpmResource<TRecord>[] {
        return Array.from<MpmResource<TRecord>>(MpmResource.MAP.values());
    }

    public static getPermittedByParentName<TRecord extends MpmRecord>(
        parentName: ParentName,
        permissions: string[]
    ): MpmResource<TRecord>[] {
        // eslint-disable-next-line no-undefined,@typescript-eslint/no-unnecessary-condition
        if (permissions === undefined) {
            // eslint-disable-next-line no-param-reassign
            permissions = ["ROLE_ENTITY_MANAGER"];
        }
        const resources = [] as MpmResource<TRecord>[];
        MpmResource.MAP.forEach((value: MpmResource<TRecord>) => {
            if (
                value.props.parentMenu === parentName &&
                value.props.permissions &&
                (value.props.permissions.includes("ALL_ROLES") ||
                    value.props.permissions.filter((permission) => permissions.includes(permission)).length > 0)
            ) {
                resources.push(value);
            }
        });
        return Array.from<MpmResource<TRecord>>(resources);
    }

    public static getAllParentNames(permissions: string[]): ParentMenu[] {
        // eslint-disable-next-line no-undefined,@typescript-eslint/no-unnecessary-condition
        if (permissions === undefined) {
            // eslint-disable-next-line no-param-reassign
            permissions = ["ROLE_ENTITY_MANAGER"];
        }
        return MpmResource.PARENT_MENUS.filter((parentMenu) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const isRolePermitted = parentMenu.permissions.filter((permission) => permissions.includes(permission)).length > 0;
            return parentMenu.permissions.includes("ALL_ROLES") || isRolePermitted;
        });
    }
}
