import { Chip } from "@mui/material";
import { FunctionField, useTranslate } from "react-admin";

export const renderStatus = (record: any) => {
    const t = useTranslate();
    const status = record?.publicationStatus;

    if (status == null) {
        return <Chip />;
    }

    return <Chip label={t("resources.common.choices.publicationStatus." + status)} />;
};

export const PublicationStatusField = ({ label, source }: { label: string; source: string }) => {
    return <FunctionField source={source} label={label} render={renderStatus} />;
};
