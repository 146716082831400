import { TopToolbar, CreateButton, ExportButton, FilterButton } from "react-admin";
import type { Exporter } from "react-admin";
import { i18nProvider } from "../../../i18n";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "../../../utils/csv";
import dayjs from "dayjs";

const EXPORT_FORBIDDEN_KEYS = ["shortId", "published", "serie.id"];

const defaultExporter: Exporter = (data, _, __, resource: any) => {
    // Remove useless keys
    for (const line of data) {
        // Translate content status and publication status
        line["status"] = i18nProvider.translate(`resources.common.choices.contentStatus.${line.status}`);
        line["publicationStatus"] = i18nProvider.translate(`resources.common.choices.publicationStatus.${line.publicationStatus}`);

        const publicationStartedAt = line?.publicationStartedAt;
        const publicationEndedAt = line?.publicationEndedAt;

        if (publicationStartedAt != null) {
            line["publicationStartedAt"] = dayjs(publicationStartedAt).format("DD/MM/YYYY");
        }

        if (publicationEndedAt != null) {
            line["publicationEndedAt"] = dayjs(publicationEndedAt).format("DD/MM/YYYY");
        }

        for (const key of Object.keys(line)) {
            if (!EXPORT_FORBIDDEN_KEYS.includes(key)) {
                continue;
            }
            delete line[key];
        }
    }

    // Get headers
    const firstLine = data?.[0];
    const headers = Object.keys(firstLine);

    // Translate headers
    const rename = [];

    for (const headerId of headers) {
        const commonTranslation = i18nProvider.translate(`resources.common.fields.${headerId}`, { _: headerId });
        const defaultTranslation = i18nProvider.translate(`resources.${resource}.fields.${headerId}`, { _: commonTranslation });
        rename.push(i18nProvider.translate(`resources.${resource}.exportFields.${headerId}`, { _: defaultTranslation }));
    }

    return jsonExport(data, { rowDelimiter: ";", headers, rename }, (_err: any, csv: any) => downloadCSV(csv, resource));
};

export function SerieListActions(): JSX.Element {
    return (
        <TopToolbar sx={{ alignItems: "center" }}>
            <CreateButton />
            <ExportButton exporter={defaultExporter} />
            <FilterButton disableSaveQuery />
        </TopToolbar>
    );
}
