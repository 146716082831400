import { SelectInput, useTranslate } from "react-admin";

interface Props {
    source: string;
    label: string;
}

export function PublicationStatusFilter(props: Props) {
    const t = useTranslate();

    return (
        <SelectInput
            key="filter-publication-status"
            label={props.label}
            source={props.source}
            choices={[
                { id: "unpublished", name: t("resources.common.choices.publicationStatus.unpublished") },
                { id: "published", name: t("resources.common.choices.publicationStatus.published") },
                { id: "to-be-published", name: t("resources.common.choices.publicationStatus.to-be-published") }
            ]}
        />
    );
}
