import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import { convertIdFilter } from "../../utils/filterUtils";

import type { ProductStatisticDTO } from "./ProductStatisticDTO";
import type { ProductStatisticRecord } from "./ProductStatisticRecord";
import { RightHolderDTO } from "../rightHolder/RightHolderDTO";
import { ContentTypeDTO } from "../contentType/ContentTypeDTO";
import { FormatDTO } from "../format/FormatDTO";
import { LicenseDTO } from "../license/LicenseDTO";
import { arrayRange } from "../../utils/array";
import { SeasonDTO } from "../season/SeasonDTO";
import { SerieDTO } from "../serie/SerieDTO";
import { GenreDTO } from "../genre/GenreDTO";

const MAX_PAGE_SIZE = 500;

const findAllTranslated = fetcher.path("/api/statistic/product/findAll").method("post").create();

async function getPage(parameters: FetchArgType<typeof findAllTranslated>) {
    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: 0, ...content })),
        total
    };
}

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns UserRecord
 */

function mapDTOToRecord(dto: ProductStatisticDTO): ProductStatisticRecord {
    // DTO from backend
    const {
        productUuid = "",
        title = "",
        createdAt,
        contentType,
        rightHolder,
        license,
        name = "",
        minAge,
        maxAge,
        genres,
        licenseDuration = null,
        publicationStartedAt = null,
        publicationEndedAt = null,
        season,
        serie,
        format,
        codeEm = "",
        codeEmArte = "",
        playCount,
        licenseCount
    } = dto;

    // Record for front-end
    return {
        id: productUuid,
        uuid: productUuid,
        codeEm,
        codeEmArte,
        title,
        createdAt,
        name,
        minAge,
        maxAge,
        genres: genres as GenreDTO[],
        licenseDuration,
        publicationStartedAt,
        publicationEndedAt,
        rightHolder: rightHolder as RightHolderDTO,
        rightHolderId: rightHolder?.["uuid"] as string,
        contentType: contentType as ContentTypeDTO,
        contentTypeId: contentType?.["uuid"] as string,
        format: format as FormatDTO,
        formatId: format?.["uuid"] as string,
        license: license as LicenseDTO,
        licenseId: license?.["uuid"] as string,
        serie: serie as SerieDTO,
        serieId: serie?.["uuid"] as string,
        season: season as SeasonDTO,
        seasonId: season?.["uuid"] as string,
        playCount,
        licenseCount
    } as ProductStatisticRecord;
}

interface ProductStatisticFilters extends BaseFilters {
    codeEm?: string;
    codeEmArte?: string;
    contentTypeUuid?: string;
    formatUuid?: string;
    rightHolderUuid?: string;
    dateBegin?: string;
    dateEnd?: string;
    testAccount?: boolean;
}

function convertFilters(filters: ProductStatisticFilters): any {
    const newFilters = Object.assign({}, filters) as any;
    return convertIdFilter(newFilters);
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<ProductStatisticRecord>> {
    const { ...filters } = params.filter as ProductStatisticFilters;

    let parameters: FetchArgType<typeof findAllTranslated> = {
        filter: convertFilters(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    const pageable = parameters.pageable;
    const origPageSize = pageable?.size ?? 0;

    if (pageable?.page == 0 && origPageSize > MAX_PAGE_SIZE) {
        // Get total elements
        const newParameters: FetchArgType<typeof findAllTranslated> = {
            filter: parameters.filter ?? {},
            pageable: {
                page: 0,
                size: 1,
                sort: parameters.pageable?.sort ?? []
            }
        };
        const headPage = await getPage(newParameters);
        const newPageSize = Math.min(origPageSize, headPage.total);
        console.log("##### new page size", newPageSize);
        // Use pagination
        const pageIds = arrayRange(pageable?.page, newPageSize / MAX_PAGE_SIZE, 1);

        const pages = await Promise.all(
            pageIds.map((pageId) => {
                const newParameters: FetchArgType<typeof findAllTranslated> = {
                    filter: parameters.filter ?? {},
                    pageable: {
                        page: pageId,
                        size: MAX_PAGE_SIZE,
                        sort: parameters.pageable?.sort ?? []
                    }
                };
                return getPage(newParameters);
            })
        );

        const allData = [];

        for (const page of pages) {
            allData.push(...page.data);
        }

        return {
            data: allData.slice(0, origPageSize),
            total: pages?.[0]?.total ?? 0
        };
    }

    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: 0, ...content })),
        total
    };
}

async function getOne(_params: LangParams | (number | string)): Promise<ProductStatisticRecord> {
    return Promise.reject(new NotImplementedError("_getOne"));
}

async function getMany(_uuids: readonly (number | string)[]): Promise<GetManyResult<ProductStatisticRecord>> {
    return Promise.reject(new NotImplementedError("getMany"));
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<ProductStatisticRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(_params: DeepReadonly<UpdateParams<ProductStatisticRecord>>): Promise<UpdateResult<ProductStatisticRecord>> {
    return Promise.reject(new NotImplementedError("update"));
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(_params: DeepReadonly<CreateParams>): Promise<CreateResult<ProductStatisticRecord>> {
    return Promise.reject(new NotImplementedError("create"));
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(_uuid: number | string): Promise<ProductStatisticRecord> {
    return Promise.reject(new NotImplementedError("delete"));
}

async function deleteMany(_params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    return Promise.reject(new NotImplementedError("deleteMany"));
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<ProductStatisticRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const ProductStatisticDataProvider: MpmDataProvider<ProductStatisticRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne,
    update,
    updateMany
};
