import { Login, LoginProps } from "react-admin";
import { LoginForm } from "./LoginForm";

const LoginPage = (props: LoginProps) => {
    return (
        <Login {...props}>
            <LoginForm />
        </Login>
    );
};

export default LoginPage;
